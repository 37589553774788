import {
  cloneDeep,
  find,
  isFinite,
  isNil,
  map,
  merge,
  reduce,
  trim,
  isEmpty,
} from 'lodash';
import {
  endOfDay,
  format,
  isValid,
  parse,
  startOfDay,
} from 'date-fns';
import { parseJsonArray } from '@/services/helpers/json';
import { createNestedObject } from '@/services/helpers/object';

export const FilterType = {
  INTEGER: 'INTEGER',
  DECIMAL: 'DECIMAL',
  BOOLEAN: 'BOOLEAN',
  STRING: 'STRING',
  DATE: 'DATE',
  SPORT: 'SPORT',
  ODDS: 'ODDS',
};

export const FilterCondition = {
  EQUAL: 'EQUAL',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
};

export const defineFilter = (options) => {
  if (!options?.id) throw new Error('Must set filter id');
  if (!options?.type) throw new Error('Must set filter type');

  return cloneDeep({
    id: options.id,
    type: options.type,
    label: options.label || 'Unnamed Filter',
    meta: options.meta || {},
    transform: options.transform || ((value) => Promise.resolve(value)),
    validator: options.validator || (() => ''),
  });
};

export const getFilterConditions = () => [
  {
    id: FilterCondition.EQUAL,
    label: 'Equals (=)',
  },
  {
    id: FilterCondition.LESS_THAN,
    label: 'Less than (<)',
  },
  {
    id: FilterCondition.LESS_THAN_OR_EQUAL,
    label: 'Less than or equal (<=)',
  },
  {
    id: FilterCondition.GREATER_THAN,
    label: 'Greater than (>)',
  },
  {
    id: FilterCondition.GREATER_THAN_OR_EQUAL,
    label: 'Greater than or equal (>=)',
  },
];

export const serializeFilterQuery = (allFilters, options) => {
  const simpleFilters = map(allFilters, ({
    type,
    id,
    condition,
    value,
  }) => {
    if (type !== FilterType.ODDS) {
      return {
        id,
        condition: condition?.id || null,
        value: isNil(value) ? null : value,
      };
    }

    const oddsFormat = options.oddsFormat || '';
    let formattedParameter = {};
    if (oddsFormat === 'AMERICAN') {
      formattedParameter = {
        originalFormattedPrice: {
          signIsPlus: value >= 0,
          value: Math.abs(value),
          priceType: 'american',
        },
        format: 'ORIGINAL_FORMATTED_PRICE',
      };
    }
    if (oddsFormat === 'DECIMAL') {
      formattedParameter = {
        decimalPrice: value,
        format: 'DECIMAL_PRICE',
      };
    }
    if (oddsFormat === 'PROBABILITY') {
      formattedParameter = {
        probability: parseFloat(value / 100),
        format: 'PROBABILITY',
      };
    }

    return {
      id,
      condition: condition?.id || null,
      value: isEmpty(formattedParameter) ? null : formattedParameter,
    };
  });
  return encodeURIComponent(JSON.stringify(simpleFilters));
};

export const parseFilterCondition = ({ condition: id }) => find(getFilterConditions(), { id }) || null;

export const parseNumericFilterValue = (value) => {
  if (isNil(value)) return null;
  const numericValue = Number(value);
  return isFinite(numericValue) ? numericValue : null;
};

const DATE_REGEX = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/;

export const parseDateFilterValue = (value) => {
  if (!DATE_REGEX.test(value)) return null;
  const parsedDate = parse(value, 'dd/LL/yyyy', new Date());
  if (!isValid(parsedDate)) return null;
  return format(parsedDate, 'dd/LL/yyyy');
};

export const parseOddsFilterValue = (value, options) => {
  const oddsFormat = options.oddsFormat || '';
  let odd = value?.decimalPrice || 0;
  if (oddsFormat === 'AMERICAN') {
    const originalPrice = value?.originalFormattedPrice || {};
    odd = originalPrice?.signIsPlus ? originalPrice?.value || 0 : -Math.abs(originalPrice?.value || 0);
  }
  if (oddsFormat === 'PROBABILITY') {
    odd = value?.probability || 0;
  }
  return odd;
};

export const parseFilterValue = ({ type, value }, options) => {
  switch (type) {
  case FilterType.INTEGER:
  case FilterType.DECIMAL:
    return parseNumericFilterValue(value, options);
  case FilterType.DATE:
    return parseDateFilterValue(value, options);
  case FilterType.ODDS:
    return parseOddsFilterValue(value, options);
  default:
    return value || null;
  }
};

export const deserializeFilterQuery = (allFilterDefs, filterQuery, options) => {
  const allFilters = parseJsonArray(decodeURIComponent(filterQuery));
  const allValidFilters = reduce(allFilters, (result, { id, condition, value }) => {
    const filterDef = find(allFilterDefs, { id }) || {};
    const filterObject = { ...filterDef, condition, value };
    return (filterDef ? [...result, filterObject] : result);
  }, []);
  return map(allValidFilters, (filterObject) => ({
    ...filterObject,
    condition: parseFilterCondition(filterObject),
    value: parseFilterValue(filterObject, options),
  }));
};

export const transformFilters = async (allFilters) => {
  const transformPromises = map(allFilters, (filterObject) => new Promise((resolve) => {
    filterObject.transform(filterObject.value)
      .then((value) => ({ ...filterObject, value }))
      .then(resolve);
  }));
  return Promise.all(transformPromises);
};

export const validateFilters = (allFilters) => reduce(allFilters, (result, filterObject) => {
  const error = filterObject.validator(filterObject.value);
  return (error ? [...result, error] : result);
}, []);

export const isNumericFilterActive = ({ condition, value }) => condition?.id && `${value}`.length && !isNil(value);

export const isFilterActive = ({ type, condition, value }) => {
  switch (type) {
  case FilterType.INTEGER:
  case FilterType.DECIMAL:
    return isNumericFilterActive({ condition, value });
  default:
    return condition?.id && value;
  }
};

export const isFilterInactive = (filterObject) => !isFilterActive(filterObject);

export const isFilterValid = (filterObject) => !filterObject.validator(filterObject.value);

export const isFilterInvalid = (filterObject) => !isFilterValid(filterObject);

export const isFilterApplied = (filterObject) => isFilterActive(filterObject) && isFilterValid(filterObject);

export const mapStringFilterToGraphQl = ({ value, meta: { gqlField } }) => {
  if (!trim(value).length) return {};
  return createNestedObject(`filter.${gqlField}.includesInsensitive`, value);
};

export const mapBooleanFilterToGraphQl = ({ value, meta: { gqlField } }) => {
  if (!value) return {};
  return createNestedObject(`condition.${gqlField}`, true);
};

export const mapNumericFilterToGraphQl = ({
  id,
  condition,
  value,
  meta: { gqlField },
}) => {
  switch (condition?.id) {
  case FilterCondition.EQUAL:
    return createNestedObject(`filter.${gqlField}.equalTo`, value);
  case FilterCondition.LESS_THAN:
    return createNestedObject(`filter.${gqlField}.lessThan`, value);
  case FilterCondition.LESS_THAN_OR_EQUAL:
    return createNestedObject(`filter.${gqlField}.lessThanOrEqualTo`, value);
  case FilterCondition.GREATER_THAN:
    return createNestedObject(`filter.${gqlField}.greaterThan`, value);
  case FilterCondition.GREATER_THAN_OR_EQUAL:
    return createNestedObject(`filter.${gqlField}.greaterThanOrEqualTo`, value);
  default:
    console.warn('Unhandled GQL numeric mapping for filter:', id);
    return {};
  }
};

export const mapDateFilterToGraphQl = ({
  id,
  condition,
  value,
  meta: { gqlField },
}) => {
  const dateValue = parse(value, 'dd/LL/yyyy', new Date());
  switch (condition?.id) {
  case FilterCondition.EQUAL:
    return merge(
      {},
      createNestedObject(`filter.${gqlField}.greaterThanOrEqualTo`, startOfDay(dateValue)),
      createNestedObject(`filter.${gqlField}.lessThanOrEqualTo`, endOfDay(dateValue)),
    );
  case FilterCondition.LESS_THAN:
    return createNestedObject(`filter.${gqlField}.lessThan`, startOfDay(dateValue));
  case FilterCondition.LESS_THAN_OR_EQUAL:
    return createNestedObject(`filter.${gqlField}.lessThanOrEqualTo`, endOfDay(dateValue));
  case FilterCondition.GREATER_THAN:
    return createNestedObject(`filter.${gqlField}.greaterThan`, endOfDay(dateValue));
  case FilterCondition.GREATER_THAN_OR_EQUAL:
    return createNestedObject(`filter.${gqlField}.greaterThanOrEqualTo`, startOfDay(dateValue));
  default:
    console.warn('Unhandled GQL numeric mapping for filter:', id);
    return {};
  }
};

export const mapFilterToGraphQl = (filterObject, options = {}) => {
  const wildcardMapper = options?.wildcardMapper || (() => {});

  if (filterObject.meta.gqlField === '*') return wildcardMapper(filterObject, options?.wildcardMapperOptions);

  switch (filterObject.type) {
  case FilterType.STRING:
    return mapStringFilterToGraphQl(filterObject);
  case FilterType.BOOLEAN:
    return mapBooleanFilterToGraphQl(filterObject);
  case FilterType.INTEGER:
  case FilterType.DECIMAL:
    return mapNumericFilterToGraphQl(filterObject);
  case FilterType.DATE:
    return mapDateFilterToGraphQl(filterObject);
  default:
    console.warn('Unhandled GQL mapping for filter:', filterObject.id);
    return {};
  }
};

export const bulkMapFiltersToGraphQl = (allFilterObjects, options = {}) => reduce(
  allFilterObjects,
  (result, filterObject) => (isFilterApplied(filterObject) ? merge({}, result, mapFilterToGraphQl(filterObject, options)) : result),
  {},
);
