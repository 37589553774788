import { join, split, reverse } from 'lodash';
import Event from '@/models/event';
import SoccerEvent from '@/models/soccer-event';
import BasketballEvent from '@/models/basketball-event';
import FootballEvent from '@/models/football-event';
import UltimateEvent from '@/models/ultimate-event';
import HockeyEvent from '@/models/hockey-event';
import BaseballEvent from '@/models/baseball-event';
import Soccer from '@/services/helpers/soccer';
import Basketball from '@/services/helpers/basketball';
import Football from '@/services/helpers/football';
import Ultimate from '@/services/helpers/ultimate';
import Hockey from '@/services/helpers/hockey';
import Baseball from '@/services/helpers/baseball';
import sportIds from '@/services/helpers/sports';
import { isAllowedUserRole } from '@/services/helpers/navbar';
import { getAllowedRoles } from '@/../config/userRoles';

const {
  BASKETBALL_ID,
  FOOTBALL_ID,
  SOCCER_ID,
  ULTIMATE_ID,
  HOCKEY_ID,
  BASEBALL_ID,
} = sportIds;

export const getEventDrawerTabTypes = () => {
  let tabs = {
    INFO: 'INFO',
    STATS: 'STATS',
    MARKETS: 'MARKETS',
  };
  const allowedUserRoles = getAllowedRoles('playerSetup');
  const isAllowedRole = isAllowedUserRole(allowedUserRoles);
  if (isAllowedRole) {
    tabs = {
      ...tabs,
      GAME_PARAMS: 'GAME_PARAMS',
      PLAYER_PARAMS: 'PLAYER_PARAMS',
    };
  }
  return tabs;
};

export const parseEventModel = (eventData) => {
  if (!eventData) return null;
  if (eventData.alreadyMapped) return eventData;
  switch (eventData?.sportId) {
  case SOCCER_ID:
    return SoccerEvent(eventData);
  case BASKETBALL_ID:
    return BasketballEvent(eventData);
  case FOOTBALL_ID:
    return FootballEvent(eventData);
  case ULTIMATE_ID:
    return UltimateEvent(eventData);
  case HOCKEY_ID:
    return HockeyEvent(eventData);
  case BASEBALL_ID:
    return BaseballEvent(eventData);
  default:
    return Event(eventData);
  }
};

export const makeEventHeading = (event) => {
  if (!event) return 'N/A';
  return event.isUSAView
    ? join(reverse(split(event.name, ' v ')), ' @ ')
    : event.name;
};

export const makeEventSubheading = (event) => {
  const safeSportName = event?.sport?.name || 'N/A';
  const safeRegionName = event?.region?.name || 'N/A';
  const safeCompetitionName = event?.competition?.name || 'N/A';
  return `${safeSportName} • ${safeRegionName} • ${safeCompetitionName}`;
};

export const getSportUtils = (sportId) => {
  switch (sportId) {
  case SOCCER_ID:
    return Soccer;
  case BASKETBALL_ID:
    return Basketball;
  case FOOTBALL_ID:
    return Football;
  case ULTIMATE_ID:
    return Ultimate;
  case HOCKEY_ID:
    return Hockey;
  case BASEBALL_ID:
    return Baseball;
  default:
    return () => {
      console.warn('Sport utils function not found for sport:', sportId);
    };
  }
};
