import { orderBy } from 'lodash';

/**
 * Sorts an array of regions by region name.
 *
 * @param {array} regions Array of regions
 * @returns {array} Returns the new sorted array
 */
export const sortRegions = (regions) => orderBy(regions, 'regionName');

/**
 * Sorts an array of competitions by competition name.
 *
 * @param {array} competitions Array of competitions
 * @returns Returns the new sorted array
 */
export const sortCompetitions = (competitions) => orderBy(competitions, 'competitionName');

/**
 * Transforms an array of competitions into an array of grouped regions and competitions.
 *
 * In order to transform competitions for hierarchy sidebar, we need to extract distinct regions from
 * competitions. Once extracted, we need to append sportId to regions, and append sportId and regionId
 * to competitions. We append the IDs to allow for easier filtering for the display.
 *
 * @param {array} options.competitions Array of competitions
 * @param {string} options.sportId Sport ID to append to regions and competitions
 * @returns Returns the array of grouped regions (0th index) and competitions (1st index)
 */
export const partitionCompetitions = (options) => {
  const { competitions: competitionsWithRegions = [], sportId = '' } = options ?? {};

  const { regions, competitions } = competitionsWithRegions.reduce(
    (partitions, competition) => ({
      regions: {
        ...partitions.regions,
        [competition?.region?.regionId]: {
          ...(competition?.region ?? {}),
          sportId,
        },
      },
      competitions: {
        ...partitions.competitions,
        [competition?.competitionId]: {
          sportId,
          regionId: competition?.region?.regionId,
          competitionId: competition?.competitionId,
          competitionName: competition?.competitionName,
          hasCoreMarkets: !!competition?.core?.totalCount > 0,
          hasMicroMarkets: !!competition?.micro?.totalCount > 0,
          hasPlayerPropsMarkets: !!competition?.playerProps?.totalCount > 0,
          hasSgpMarkets: !!competition?.sameGameParlays?.totalCount > 0,
          regionName: competition?.region?.regionName,
          sportName: competition?.sport?.sportName,
          subscribed: !!competition?.core?.totalCount > 0,
          mapped: competition?.mappings?.nodes?.length,
        },
      },
    }),
    {
      regions: {},
      competitions: {},
    },
  );

  return [
    sortRegions(Object.values(regions)),
    sortCompetitions(Object.values(competitions)),
  ];
};
