import { isArray } from 'lodash';

export const parseJsonOrDefault = (json, defaultValue = null) => {
  try {
    return JSON.parse(json);
  } catch {
    return defaultValue;
  }
};

export const parseJsonArray = (json) => {
  const value = parseJsonOrDefault(json, []);
  return (isArray(value) ? value : []);
};
